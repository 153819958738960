import { useContext, useRef, useState } from "react";
import GlobalContext from "../../context/GlobalContext/GlobalContext";

const ResetPassForm = ({ setOpen, setModalState }) => {
  const { webEventInfo, resetPass } = useContext(GlobalContext);
  const [formData, setFormData] = useState({
    email: "",
  });

  const [textAlert, setTextAlert] = useState("");
  const errorAlertRef = useRef(<></>);
  const successAlertRef = useRef(<></>);

  const showErrorAlert = () => {
    errorAlertRef.current.classList.remove("hidden");
    errorAlertRef.current.classList.add("flex");
  };
  const showSuccessAlert = () => {
    successAlertRef.current.classList.remove("hidden");
    successAlertRef.current.classList.add("flex");
  };

  const hideAllAlert = () => {
    errorAlertRef.current.classList.remove("flex");
    errorAlertRef.current.classList.add("hidden");
    successAlertRef.current.classList.remove("flex");
    successAlertRef.current.classList.add("hidden");
  };

  const onSubmitForm = async (e) => {
    e.preventDefault();
    hideAllAlert();

    e.target[1].disabled = true;
    const result = await resetPass(formData);
    if (result.success) {
      setTextAlert(
        "Permintaan berhasil terkirim, silahkan periksa kotak masuk / kotak spam email dan lakukan reset password."
      );
      showSuccessAlert();
      setTimeout(() => {
        e.target[1].disabled = false;
      }, 3000);
    } else {
      setTextAlert(
        result.display_message ||
          "Terjadi kesalahan saat melakukan reset password, coba beberapa saat lagi"
      );
      showErrorAlert();
      e.target[1].disabled = false;
    }
  };

  return (
    <>
      {!webEventInfo ? (
        <div className="flex w-full justify-end p-7 pb-0">
          <i
            onClick={() => setOpen(false)}
            className="fas fa-times text-3xl cursor-pointer hover:text-oren transition duration-300"
          ></i>
        </div>
      ) : null}
      <div
        className={`flex flex-col gap-6 w-full px-10 pb-10 ${
          webEventInfo ? "py-10" : ""
        }`}
      >
        <h6 className="font-bold text-center">Lupa kata sandi?</h6>
        <p className="font-content-1 w-full text-center">
          Tenang, masukkan alamat email kamu dan jangan lupa untuk cek kotak
          masuk emailmu, ya!
        </p>
        <form className="flex flex-col gap-5 w-full" onSubmit={onSubmitForm}>
          <div className="w-full flex border-2 border-black rounded-xl items-center">
            <input
              className="w-full px-3 py-2 rounded-xl border-none outline-none bg-white font-content-1"
              placeholder="Email"
              type="email"
              onChange={(e) =>
                setFormData((prevState) => ({
                  ...prevState,
                  email: e.target.value,
                }))
              }
              value={formData.email}
            />
          </div>

          <div
            className="hidden p-4 mb-4 text-sm text-red-700 bg-red-100 rounded-lg"
            role="alert"
            ref={errorAlertRef}
          >
            <svg
              className="inline flex-shrink-0 mr-3 w-5 h-5"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                clipRule="evenodd"
              ></path>
            </svg>
            <div>{textAlert}</div>
          </div>
          <div
            ref={successAlertRef}
            className="hidden p-4 mb-4 text-sm text-green-700 bg-green-100 rounded-lg"
            role="alert"
          >
            <svg
              className="inline flex-shrink-0 mr-3 w-5 h-5"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                clipRule="evenodd"
              ></path>
            </svg>
            <div>{textAlert}</div>
          </div>

          <button
            type="submit"
            className="rounded-xl bg-oren text-white font-content-0 font-bold px-8 py-2"
          >
            Kirim
          </button>

          <p className="font-content-1 text-center">
            <span
              onClick={() =>
                setModalState({
                  login: true,
                  register: false,
                  registerGoogle: false,
                  resetPass: false,
                })
              }
              className="text-biru hover:underline cursor-pointer"
            >
              Kembali ke laman login
            </span>
          </p>
        </form>
      </div>
    </>
  );
};

export default ResetPassForm;
