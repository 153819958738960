import { useContext, useEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import Modal from "./Modal";
import GlobalContext from "../../context/GlobalContext/GlobalContext";

const ResetPassComponent = () => {
  const [searchParams] = useSearchParams();
  const resetPassToken = searchParams.get("reset_pass_token");
  const [isOpen, setIsOpen] = useState(false);
  useEffect(() => {
    if (resetPassToken) {
      setIsOpen(true);
    }
  }, [resetPassToken]);
  const [formData, setFormData] = useState({
    password: "",
  });
  const [passwordState, setPasswordState] = useState(true);
  const [textAlert, setTextAlert] = useState("");
  const errorAlertRef = useRef(<></>);
  const successAlertRef = useRef(<></>);
  const { changePass } = useContext(GlobalContext);

  const showErrorAlert = () => {
    errorAlertRef.current.classList.remove("hidden");
    errorAlertRef.current.classList.add("flex");
  };
  const showSuccessAlert = () => {
    successAlertRef.current.classList.remove("hidden");
    successAlertRef.current.classList.add("flex");
  };

  const hideAllAlert = () => {
    errorAlertRef.current.classList.remove("flex");
    errorAlertRef.current.classList.add("hidden");
    successAlertRef.current.classList.remove("flex");
    successAlertRef.current.classList.add("hidden");
  };

  const onSubmitForm = async (e) => {
    e.preventDefault();
    hideAllAlert();
    let completed = true;
    let safe = true;
    Object.keys(formData).forEach((item) => {
      if (String(formData[item]).replace(" ", "").length <= 0) {
        completed = false;
      }
    });

    if (!completed) {
      setTextAlert("Semua kolom harus diisi!");
      showErrorAlert();
    } else {
      if (safe && formData["password"].length < 6) {
        setTextAlert("Password harus berisi minimal 6 karakter!");
        showErrorAlert();
        safe = false;
      }
    }

    if (completed && safe) {
      e.target[2].disabled = true;
      formData.token = resetPassToken;
      const result = await changePass(formData);
      if (result.success) {
        setTextAlert(
          "Perubahan password berhasil, silahkan masuk dengan password baru kamu"
        );
        showSuccessAlert();
      } else {
        setTextAlert(
          result.display_message ||
            "Terjadi kesalahan saat mengubah password, coba beberapa saat lagi"
        );
        showErrorAlert();
        e.target[2].disabled = false;
      }
    }
  };

  return (
    <Modal open={isOpen} setOpen={setIsOpen}>
      <div className="relative text-left w-11/12 md:w-2/3 lg:w-1/2 xl:w-2/5 z-60 flex flex-col text-xl font-bold bg-white rounded-2xl">
        <div className="flex w-full justify-end p-7 pb-0">
          <i
            onClick={() => setIsOpen(false)}
            className="fas fa-times text-3xl cursor-pointer hover:text-oren transition duration-300"
          ></i>
        </div>
        <div className={`flex flex-col gap-10 w-full px-10 pb-10`}>
          <h6 className="font-bold text-center">Buat Password Baru</h6>
          <form onSubmit={onSubmitForm} className="flex flex-col gap-3 w-full">
            <div className="w-full flex border-2 pr-3 border-black rounded-xl items-center">
              <input
                className="w-full-5 sm:w-full-10 px-3 py-2 rounded-l-xl border-none outline-none bg-white font-content-1"
                placeholder="Masukkan kata sandi baru"
                type={passwordState ? "password" : "text"}
                autoComplete="on"
                min="6"
                onChange={(e) => {
                  setFormData({ ...formData, password: e.target.value });
                }}
                required={true}
              />
              <i
                onClick={() => {
                  setPasswordState(!passwordState);
                }}
                className={`w-5 sm:w-10 text-abu fas ${
                  passwordState ? "fa-eye-slash" : "fa-eye"
                } text-lg md:text-xl rounded-full cursor-pointer text-center`}
              ></i>
            </div>
            <div className="w-full flex border-2 pr-3 border-black rounded-xl items-center">
              <input
                className="w-full-5 sm:w-full-10 px-3 py-2 rounded-l-xl border-none outline-none bg-white font-content-1"
                placeholder="Ulangi kata sandimu"
                type={passwordState ? "password" : "text"}
                autoComplete="on"
                onChange={(e) => {
                  if (e.target.value !== formData.password) {
                    e.target.setCustomValidity("Password tidak cocok!");
                    e.target.reportValidity();
                  } else {
                    e.target.setCustomValidity("");
                  }
                }}
                required={true}
              />
              <i
                onClick={() => {
                  setPasswordState(!passwordState);
                }}
                className={`w-5 sm:w-10 text-abu fas ${
                  passwordState ? "fa-eye-slash" : "fa-eye"
                } text-lg md:text-xl rounded-full cursor-pointer text-center`}
              ></i>
            </div>

            <div
              className="hidden p-4 mb-4 text-sm text-red-700 bg-red-100 rounded-lg"
              role="alert"
              ref={errorAlertRef}
            >
              <svg
                className="inline flex-shrink-0 mr-3 w-5 h-5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                  clipRule="evenodd"
                ></path>
              </svg>
              <div>{textAlert}</div>
            </div>
            <div
              ref={successAlertRef}
              className="hidden p-4 mb-4 text-sm text-green-700 bg-green-100 rounded-lg"
              role="alert"
            >
              <svg
                className="inline flex-shrink-0 mr-3 w-5 h-5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                  clipRule="evenodd"
                ></path>
              </svg>
              <div>{textAlert}</div>
            </div>
            <button
              type="submit"
              className="rounded-xl bg-oren text-white font-content-0 font-bold px-8 py-2"
            >
              Ubah Password
            </button>
          </form>
        </div>
      </div>
    </Modal>
  );
};

export default ResetPassComponent;
