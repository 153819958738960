/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect, useRef, useState } from "react";
import CountUp from "react-countup";
import { InView } from "react-intersection-observer";
import Modal from "../components/Home/Modal";
import RegisterForm from "../components/Home/RegisterForm";
import ResetPassForm from "../components/Home/ResetPassForm";
import LoginForm2 from "../components/Home/LoginForm2";
import Footer from "../components/Home/Footer";
import RegisterGoogleForm from "../components/Home/RegisterGoogleForm";
import { useContext } from "react";
import GlobalContext from "../context/GlobalContext/GlobalContext";
import { getPendaftaranInfo, getPengerjaanInfo } from "../script/dateUtils";
import dayjs from "dayjs";
import ResetPassComponent from "../components/Home/ResetPassComponent";

const DetailComponent = ({ tryoutData, setOpen }) => {
  const { openPendaftaranDateString, endPendaftaranDateString } =
    getPendaftaranInfo(tryoutData);
  const { endPengerjaanDateString } = getPengerjaanInfo(tryoutData, false);
  return (
    <>
      <div className="flex w-full justify-end p-7 pb-0">
        <i
          onClick={() => setOpen(false)}
          className="fas fa-times text-3xl cursor-pointer hover:text-oren transition duration-300"
        ></i>
      </div>
      <div className="flex flex-col gap-6 w-full px-10 pb-10">
        <h6 className="font-bold text-center">{tryoutData.nama_tryout}</h6>
        <div className="flex flex-col gap-2 w-full">
          <p className="font-content-1 w-full text-center">
            Periode pendaftaran
          </p>
          <p className="font-content-0 w-full text-center text-oren font-bold">
            {openPendaftaranDateString} - {endPendaftaranDateString}
          </p>
          <p className="font-content-1 w-full text-center mt-4">
            Periode pengerjaan
          </p>
          <p className="font-content-0 w-full text-center text-oren font-bold">
            {openPendaftaranDateString} - {endPengerjaanDateString}
          </p>
        </div>
      </div>
    </>
  );
};

const DaftarTryoutComponent = ({ tryoutData, setOpen, onButtonMasukClick }) => {
  return (
    <>
      <div className="flex w-full justify-end p-7 pb-0">
        <i
          onClick={() => setOpen(false)}
          className="fas fa-times text-3xl cursor-pointer hover:text-oren transition duration-300"
        ></i>
      </div>
      <div className="flex flex-col gap-6 w-full px-10 pb-10">
        <h5 className="font-bold text-center">
          Daftar Tryout <span className="text-oren font-bold">Premium</span>
        </h5>
        <div className="flex flex-col gap-2 w-full">
          <p className="font-content-1 w-full text-center">
            Dengan mendaftar Try Out premium, kamu bisa mendapatkan berbagai
            macam fasilitas Try Out.
          </p>
        </div>
        <div className="flex flex-col w-full text-center gap-3">
          <div className="flex flex-row w-full">
            <div className="flex w-1/4 flex-col items-center justify-center">
              <p className="font-subtitle font-bold">Premium</p>
              <h6 className="font-subtitle font-bold">IDR 15K</h6>
            </div>
            <div className="flex w-2/4"></div>
            <div className="flex w-1/4 flex-col items-center justify-center">
              <p className="font-subtitle font-bold">Gratis</p>
              <h6 className="font-subtitle font-bold">IDR 0K</h6>
            </div>
          </div>

          <div className="flex flex-row w-full">
            <div className="flex w-1/4 items-center justify-center">
              <i className="fa-solid fa-star text-oren"></i>
            </div>
            <div className="flex w-2/4 font-content-0 justify-center">
              Try Out CBT dengan penilaian IRT
            </div>
            <div className="flex w-1/4 items-center justify-center">
              <i className="fa-solid fa-star text-oren"></i>
            </div>
          </div>
          <div className="flex flex-row w-full">
            <div className="flex w-1/4 items-center justify-center">
              <i className="fa-solid fa-star text-oren"></i>
            </div>
            <div className="flex w-2/4 font-content-0 justify-center">
              Sertifikat nilai hasil Try Out
            </div>
            <div className="flex w-1/4 items-center justify-center">
              <i className="fa-solid fa-star text-oren"></i>
            </div>
          </div>
          <div className="flex flex-row w-full">
            <div className="flex w-1/4 items-center justify-center">
              <i className="fa-solid fa-star text-oren"></i>
            </div>
            <div className="flex w-2/4 font-content-0 justify-center">
              Tanpa syarat share
            </div>
            <div className="flex w-1/4 items-center justify-center">
              <i
                className="fa-solid fa-star text-oren"
                style={{
                  color: "transparent",
                  WebkitTextStrokeWidth: "2px",
                  WebkitTextStrokeColor: "black",
                }}
              ></i>
            </div>
          </div>
          <div className="flex flex-row w-full">
            <div className="flex w-1/4 items-center justify-center">
              <i className="fa-solid fa-star text-oren"></i>
            </div>
            <div className="flex w-2/4 font-content-0 justify-center">
              Akses ranking ribuan peserta Try Out
            </div>
            <div className="flex w-1/4 items-center justify-center">
              <i
                className="fa-solid fa-star text-oren"
                style={{
                  color: "transparent",
                  WebkitTextStrokeWidth: "2px",
                  WebkitTextStrokeColor: "black",
                }}
              ></i>
            </div>
          </div>
          <div className="flex flex-row w-full">
            <div className="flex w-1/4 items-center justify-center">
              <i className="fa-solid fa-star text-oren"></i>
            </div>
            <div className="flex w-2/4 font-content-0 justify-center">
              Akses pembahasan soal
            </div>
            <div className="flex w-1/4 items-center justify-center">
              <i
                className="fa-solid fa-star text-oren"
                style={{
                  color: "transparent",
                  WebkitTextStrokeWidth: "2px",
                  WebkitTextStrokeColor: "black",
                }}
              ></i>
            </div>
          </div>
        </div>

        <div className="flex justify-center gap-4 w-full">
          <button
            onClick={() => {
              setOpen(false);
              onButtonMasukClick();
            }}
            className="bg-oren font-content-0 font-bold flex items-center justify-center px-4 py-2 w-max md:w-1/3 text-white rounded-xl text-center"
          >
            Daftar Premium
          </button>
          <button
            onClick={() => {
              setOpen(false);
              onButtonMasukClick();
            }}
            className="border-2 border-oren font-content-0 flex items-center justify-center px-4 py-2 w-max md:w-1/3 text-black rounded-xl text-center"
          >
            Daftar Gratis
          </button>
        </div>
      </div>
    </>
  );
};

const TryoutRegister = ({
  data,
  setModalContent,
  setOpen,
  addProp,
  onButtonMasukClick,
}) => {
  return (
    <div className="flex flex-col justify-center bg-white shadow-lg rounded-2xl p-7 gap-3 w-96">
      <div className="flex items-center gap-5">
        <p className="font-content-0 font-bold">{data.nama_tryout}</p>
        <i
          onClick={() => {
            setModalContent(
              <DetailComponent tryoutData={data} setOpen={setOpen} />
            );
            setOpen(true);
          }}
          className="fa-solid fa-circle-question text-xl text-abu hover:cursor-pointer"
        ></i>
      </div>
      <p className="font-content-1 mt-2 text-left">
        Kamu bisa mendaftar pada :
      </p>
      <div className="flex justify-center items-center px-5 py-3 bg-krem rounded-xl">
        <p className="font-content-1 font-bold text-merah">
          {addProp.openPendaftaranDateString} -{" "}
          {addProp.endPendaftaranDateString}
        </p>
      </div>
      <button
        disabled={
          addProp.currentTimestamp < addProp.openPendaftaranDateTimestamp
        }
        onClick={() => {
          if (addProp.currentTimestamp > addProp.openPendaftaranDateTimestamp) {
            setModalContent(
              <DaftarTryoutComponent
                tryoutData={data}
                setOpen={setOpen}
                onButtonMasukClick={onButtonMasukClick}
              />
            );
            setOpen(true);
          }
        }}
        className="bg-oren font-content-0 font-bold flex items-center justify-center px-4 py-2 w-max md:w-1/2 text-white rounded-xl"
      >
        Buka
      </button>
    </div>
  );
};

const Home = () => {
  const {
    tryoutList,
    totalAkun,
    getTryoutList,
    getTotalAkun,
    webEventInfo,
    webEventInfoLoading,
  } = useContext(GlobalContext);
  const tentangRef = useRef(<></>);
  const fiturRef = useRef(<></>);
  const testiRef = useRef(<></>);
  useEffect(() => {
    getTryoutList();
    getTotalAkun();
  }, []);

  const [open, setOpen] = useState(false);
  const [openModal2, setOpenModal2] = useState(false);
  const [modalState, setModalState] = useState({
    login: true,
    register: false,
    resetPass: false,
  });
  const [open3, setOpen3] = useState(false);
  const [modalContent, setModalContent] = useState(<></>);

  useEffect(() => {
    if (!open && !openModal2) {
      setTimeout(() => {
        document.querySelector("html").style.overflow = "auto";
      }, 500);
    } else if (open) {
      document.querySelector("html").style.overflow = "hidden";
    }
  }, [open, openModal2]);

  const onButtonMasukClick = () => {
    setModalState({
      login: true,
      register: false,
      registerGoogle: false,
      resetPass: false,
    });
    setOpenModal2(true);
  };

  const Menu = ({ children, refer }) => {
    let overlap = 0;
    return (
      <div
        className={`transition duration-300 hover:text-oren cursor-pointer`}
        onClick={() => {
          setOpen(false);
          window.scrollTo({
            top: refer.current.offsetTop - overlap,
            behavior: "smooth",
          });
        }}
      >
        {children}
      </div>
    );
  };

  if (webEventInfo) {
    return (
      <>
        <div className="flex flex-col justify-center items-center w-full min-h-screen h-auto bg-krem py-10">
          <div className="absolute flex justify-center items-center w-1/4 sm:w-1/6 md:w-1/12 left-1/2 -translate-x-1/2 sm:left-8 sm:translate-x-0 top-8">
            <img
              src={
                webEventInfo?.logo_url?.[0] == "/"
                  ? process.env.PUBLIC_URL + webEventInfo?.logo_url
                  : webEventInfo?.logo_url
              }
              className="w-full h-auto object-contain"
            ></img>
          </div>

          <div
            className={`relative text-left w-11/12 md:w-2/3 lg:w-1/2 xl:w-1/3 z-50 flex flex-col text-xl font-bold bg-white rounded-2xl ${
              modalState.register ? "mt-16 md:mt-0" : ""
            }`}
          >
            {modalState &&
              (modalState.login ? (
                <LoginForm2
                  setOpen={setOpenModal2}
                  setModalState={setModalState}
                />
              ) : modalState.register ? (
                <RegisterForm
                  setOpen={setOpenModal2}
                  setModalState={setModalState}
                  data={modalState.data}
                />
              ) : modalState.registerGoogle ? (
                <RegisterGoogleForm
                  setOpen={setOpenModal2}
                  data={modalState.data}
                />
              ) : (
                <ResetPassForm
                  setOpen={setOpenModal2}
                  setModalState={setModalState}
                />
              ))}
          </div>
        </div>
        <ResetPassComponent />
      </>
    );
  }

  return (
    <>
      <div className="flex flex-col bg-white items-center p-0 m-0">
        {/* Navbar */}
        <div className="w-full p-7 md:p-10 flex justify-between items-center">
          <div className="h-7 md:h-12 w-auto">
            <img
              className="h-full"
              src={`${process.env.PUBLIC_URL}/images/logo-jd-dark.png`}
              alt="jd"
            />
          </div>
          <div className="hidden md:flex gap-16 items-center">
            <Menu refer={tentangRef}>
              <p className="font-content-0">Tentang</p>
            </Menu>
            <Menu refer={fiturRef}>
              <p className="font-content-0">Fitur</p>
            </Menu>
            <Menu refer={testiRef}>
              <p className="font-content-0">Review</p>
            </Menu>

            <button
              onClick={onButtonMasukClick}
              className="rounded-xl bg-oren text-white font-content-0 font-bold px-8 py-2"
            >
              Masuk
            </button>
          </div>
          <div className="flex md:hidden items-center justify-center">
            <i
              onClick={() => setOpen(true)}
              className="fas fa-bars text-3xl text-black cursor-pointer transition duration-300"
            ></i>
          </div>
        </div>
        <Modal {...{ open, setOpen }} positionStart={true}>
          <div className="relative text-left w-full z-50 flex flex-col text-xl font-bold mt-20 bg-white rounded-b-lg">
            <div className="py-2 px-7">
              <Menu refer={tentangRef}>
                <p className="font-content-0">Tentang</p>
              </Menu>
            </div>

            <div className="py-2 px-7">
              <Menu refer={fiturRef}>
                <p className="font-content-0">Fitur</p>
              </Menu>
            </div>

            <div className="py-2 px-7">
              <Menu refer={testiRef}>
                <p className="font-content-0">Testimoni</p>
              </Menu>
            </div>

            <div className="py-2 px-7">
              <button
                onClick={() => {
                  setOpen(false);
                  onButtonMasukClick();
                }}
                className="rounded-xl bg-oren text-white  w-full font-content-0 font-bold px-2 py-1"
              >
                Masuk
              </button>
            </div>
          </div>
        </Modal>

        {/* Section 1 */}
        <div className="flex w-full px-5 sm:px-10 relative overflow-hidden">
          <div
            className="flex justify-between w-full rounded-2xl px-0 overflow-hidden"
            style={{
              background:
                "linear-gradient(180deg, #FFECC9 0%, rgba(255, 236, 201, 0) 100%)",
            }}
          >
            <img
              src={`${process.env.PUBLIC_URL}/images/home-ornamen.png`}
              className="w-full object-cover absolute z-0"
              alt="ornamen"
            />
            <div className="flex flex-col justify-center items-center z-10 px-8 sm:px-20 lg:pl-20 py-20 w-full lg:w-1/2">
              <h4 className="font-extrabold w-full mb-4 text-center md:text-left">
                Uji Persiapanmu Bersama Jalur Dalam
              </h4>
              <p className="font-content-1 w-full mb-10 text-center md:text-left">
                Persiapkan masa depan menuju ke perguruan tinggi impianmu hanya
                di Jalur Dalam dengan pengalaman Try Out dan materi yang sesuai
                dengan SNBT terbaru.
              </p>
              <div className="flex w-full justify-center md:justify-start">
                <button
                  onClick={onButtonMasukClick}
                  className="rounded-xl bg-oren text-white font-content-0 font-bold px-8 py-2"
                >
                  Gabung Sekarang
                </button>
              </div>
            </div>
            {/* <div className="w-2/5 z-10 pt-20 hidden lg:block"> */}
            <div className="w-2/5 z-10 hidden lg:flex items-center justify-center pr-20">
              <img
                // src={`${process.env.PUBLIC_URL}/images/home-cover.png`}
                src={`${process.env.PUBLIC_URL}/images/logo-jd-dark.png`}
                className="w-full h-auto"
                alt="siswa"
              />
            </div>
          </div>
        </div>

        {/* Section Try Out */}
        {(tryoutList.length && (
          <div
            ref={testiRef}
            className="flex w-full px-8 md:px-20 py-24 flex-col relative overflow-hidden items-center text-center"
          >
            <h6 className="font-bold mb-10">Try Out Berlangsung</h6>
            <div className="flex gap-10 flex-wrap w-full justify-center">
              {tryoutList.map((item, index) => {
                // pendaftaran
                const {
                  openPendaftaranDateString,
                  openPendaftaranDateTimestamp,
                  endPendaftaranDateString,
                } = getPendaftaranInfo(item);

                // current timestamp
                const currentTimestamp = dayjs().valueOf();

                return (
                  <TryoutRegister
                    key={index}
                    data={item}
                    setModalContent={setModalContent}
                    setOpen={setOpen3}
                    addProp={{
                      currentTimestamp,
                      openPendaftaranDateString,
                      endPendaftaranDateString,
                      openPendaftaranDateTimestamp,
                    }}
                    onButtonMasukClick={onButtonMasukClick}
                  />
                );
              })}
            </div>
          </div>
        )) ||
          ""}

        {/* Section 2 */}
        <div
          ref={tentangRef}
          className="flex flex-col-reverse md:flex-row w-full px-10 md:px-20 py-10 md:py-32 justify-between relative overflow-hidden"
        >
          <div className="w-full md:w-2/5 flex flex-col justify-center text-center md:text-left md:pl-5">
            <h6 className="font-extrabold mb-4">Apa itu Jalur Dalam?</h6>
            <p className="font-content-1">
              Jalur Dalam adalah sebuah platform yang bergerak di bidang
              pendidikan dengan fokus utama menyediakan berbagai sarana
              prasarana kepada para pelajar agar mampu menghadapi ujian masuk
              kampus dengan maksimal dengan cara menyediakan berbagai fasilitas
              berupa Try Out Online, berlangganan konten latihan soal, konten
              info seputar ujian, dan lain lain.
            </p>
          </div>
          <div className="w-full md:w-1/3 justify-center items-center flex p-10 md:p-0">
            {/* <img
              className="w-40 md:w-3/4 h-auto"
              src={`${process.env.PUBLIC_URL}/images/logo-jd-dark.png`}
              alt="jd"
            /> */}
            <i className="fa-solid fa-graduation-cap text-7xl md:text-9xl"></i>
          </div>
        </div>

        {/* Section 3 */}
        <div
          ref={fiturRef}
          className="relative flex flex-col w-full px-8 md:px-20 py-20 md:py-32 gap-0 overflow-hidden"
          style={{
            background:
              "linear-gradient(180deg, rgba(255, 236, 201, 0.0555556) 0%, #FFECC9 53.12%, rgba(255, 236, 201, 0) 100%)",
          }}
        >
          <div className="absolute w-screenjustify-end bottom-52 right-24">
            <img
              className="w-3/4 md:w-auto"
              src={`${process.env.PUBLIC_URL}/images/home-ellipse.png`}
              alt=""
            />
          </div>
          {/* per item */}
          <div className="flex w-full justify-end">
            {/* logo */}
            <div className="flex flex-col h-30 w-1/5 justify-start text-center items-center">
              <div className="bg-white rounded-full p-6 aspect-square w-fit h-fit">
                <i className="fas fa-desktop text-3xl md:text-5xl text-abu"></i>
              </div>
              <div
                className="h-24 w-3 border-none bg-white flex-auto"
                style={{ transform: "scale(1.2)" }}
              ></div>
            </div>
            {/* tulisan */}
            <div className="flex flex-col h-auto w-4/5 md:w-2/5 pl-10 md:pl-0">
              <p className="font-subtitle font-bold">Computer Based Test</p>
              <p className="font-content-1 w-full md:w-4/5 lg:1/2">
                Kenali sistem SNBT melalui tryout yang sudah didesain semirip
                mungkin dengan SNBT sesungguhnya.
              </p>
            </div>
          </div>

          <div className="flex w-full justify-end md:flex-row-reverse">
            {/* logo */}
            <div className="flex flex-col h-30 w-1/5 justify-start text-center items-center">
              <div className="bg-white rounded-full p-6 aspect-square w-fit h-fit">
                <i className="fas fa-file-alt text-3xl md:text-5xl text-abu"></i>
              </div>

              <div
                className="h-24 w-3 border-none bg-white flex-auto"
                style={{ transform: "scale(1.2)" }}
              ></div>
            </div>
            {/* tulisan */}
            <div className="flex flex-col h-auto w-4/5 md:w-2/5 text-left md:text-right md:items-end pl-10 md:pl-0">
              <p className="font-subtitle font-bold">Item Response Theory</p>
              <p className="font-content-1 w-full md:w-4/5 lg:1/2">
                Sistem penilaian yang Jalur Dalam gunakan sudah menggunakan
                syatem IRT atau Poin Butir Soal sesuai ketentuan SNBT.
              </p>
            </div>
          </div>

          <div className="flex w-full justify-end">
            {/* logo */}
            <div className="flex flex-col h-30 w-1/5 justify-start text-center items-center">
              <div className="bg-white rounded-full p-6 aspect-square w-fit h-fit">
                <i className="fas fa-poll text-3xl md:text-5xl text-abu"></i>
              </div>
              <div
                className="h-24 w-3 border-none bg-white flex-auto"
                style={{ transform: "scale(1.2)" }}
              ></div>
            </div>
            {/* tulisan */}
            <div className="flex flex-col h-auto w-4/5 md:w-2/5 pl-10 md:pl-0">
              <p className="font-subtitle font-bold">Multi Ranking System</p>
              <p className="font-content-1 w-full md:w-4/5 lg:1/2">
                Jalur Dalam menyediakan fitur perankingan mulai dari Jurusan,
                Kampus, Regional, hingga Nasional agar peserta dapat mengetahui
                kemampuannya dibanding dengan peserta lain.
              </p>
            </div>
          </div>

          <div className="flex w-full justify-end md:flex-row-reverse">
            {/* logo */}
            <div className="flex flex-col h-30 w-1/5 justify-start text-center items-center">
              <div className="bg-white rounded-full p-6 aspect-square w-fit h-fit">
                <i className="fas fa-paperclip text-3xl md:text-5xl text-abu"></i>
              </div>

              <div
                className="h-24 w-3 border-none bg-white flex-auto"
                style={{ transform: "scale(1.2)" }}
              ></div>
            </div>
            {/* tulisan */}
            <div className="flex flex-col h-auto w-4/5 md:w-2/5 text-left md:text-right md:items-end pl-10 md:pl-0">
              <p className="font-subtitle font-bold">Test Solutions</p>
              <p className="font-content-1 w-full md:w-4/5 lg:1/2">
                Peserta dapat melihat dan mengetahui jawaban dan pembahasan
                untuk bisa menjadi bahan evaluasi dan pembelajaran.
              </p>
            </div>
          </div>

          <div className="flex w-full justify-end">
            {/* logo */}
            <div className="flex flex-col h-30 w-1/5 justify-start text-center items-center">
              <div className="bg-white rounded-full p-6 aspect-square w-fit h-fit">
                <i className="fas fa-award text-3xl md:text-5xl text-abu"></i>
              </div>
            </div>
            {/* tulisan */}
            <div className="flex flex-col h-auto w-4/5 md:w-2/5 pl-10 md:pl-0">
              <p className="font-subtitle font-bold">E-Certificate</p>
              <p className="font-content-1 w-full md:w-4/5 lg:1/2">
                Peserta dapat mengunduh sertifikat hasil tryout mirip seperti di
                SBMPTN sehingga peserta bisa menyimpan nilai dari tryout yang
                sudah dikerjakan
              </p>
            </div>
          </div>
        </div>

        {/* Section 4 */}
        <div className="flex w-full px-8 md:px-20 py-24 md:py-32 flex-col relative overflow-visible overflow-x-clip items-center text-center">
          <div className="absolute w-screen flex items-center top-12">
            <div className="w-1/2"></div>
            <img
              src={`${process.env.PUBLIC_URL}/images/home-ellipse.png`}
              alt=""
            />
          </div>
          <h6 className="font-bold">
            Total Pelajar yang Sudah Menggunakan Jalur Dalam
          </h6>
          <CountUp
            className="font-bold font-h3 md:font-h1 mt-4"
            start={1000}
            end={44141 + totalAkun}
            duration={2}
            useEasing={true}
            useGrouping={true}
            separator="."
            suffix="++"
          >
            {({ countUpRef, start }) => (
              <InView as="div" onChange={start}>
                <span
                  className="font-bold font-h3 md:font-h1 mt-4"
                  ref={countUpRef}
                />
              </InView>
            )}
          </CountUp>
        </div>

        {/* Section 5 */}
        <div
          ref={testiRef}
          className="flex w-full px-8 md:px-20 py-24 md:py-32 flex-col relative overflow-hidden items-center text-center"
        >
          <h6 className="font-bold mb-10">
            Kata Mereka yang Sudah Bergabung dengan Jalur Dalam
          </h6>
          <div className="flex w-full gap-7 justify-center flex-wrap text-left">
            <div className="flex flex-col gap-5 w-80 aspect-video bg-krem rounded-2xl p-7">
              <i className="fas fa-quote-left text-6xl text-oren"></i>
              <p className="font-content-0">
                Terima kasih untuk jalur dalam atas kesempatan yang diberikan,
                sehingga saya bisa mengikuti try out utbk ini secara gratis.
                sukses terus buat jalur dalam membantu temen-temen yang berjuang
                masuk kuliah. semangat!!! lolos sbmptn 2022 aamiin..
              </p>
              <p className="font-content-1 text-abu">Lutfi Nawa S</p>
            </div>
            <div className="flex flex-col gap-5 w-80 aspect-video bg-krem rounded-2xl p-7">
              <i className="fas fa-quote-left text-6xl text-oren"></i>
              <p className="font-content-0">
                Terimakasih untuk jalur dalam sudah membuat ini saya sangat
                bersyukur sekali sudah mengikuti jalur dalam ini
              </p>
              <p className="font-content-1 text-abu">Mala Divis Y</p>
            </div>
            <div className="flex flex-col gap-5 w-80 aspect-video bg-krem rounded-2xl p-7">
              <i className="fas fa-quote-left text-6xl text-oren"></i>
              <p className="font-content-0">
                Saran saya tetap mengadakan tryout seperti ini, karena inilah
                yang kami butuhkan bagi calon mahasiswa baru sampai nanti waktu
                utbk tiba
              </p>
              <p className="font-content-1 text-abu">Eric</p>
            </div>
          </div>
        </div>

        {/* Section 6 */}
        <div className="flex w-full px-8 md:px-20 py-24 md:py-32 flex-col relative overflow-hidden items-center text-center">
          <div className="absolute w-screen flex items-start top-12 px-20 md:px-56 z-0">
            <img
              className="w-3/4 md:w-auto"
              src={`${process.env.PUBLIC_URL}/images/home-ellipse-2.png`}
              alt=""
            />
          </div>
          <h5 className="font-bold mb-10 relative z-10">
            Yuk gapai impianmu bersama Jalur Dalam sekarang juga!
          </h5>
          <button
            onClick={onButtonMasukClick}
            className="rounded-xl bg-oren text-white font-content-0 font-bold px-8 py-2 relative z-10"
          >
            Gabung Sekarang
          </button>
        </div>

        {/* Footer */}
        <Footer />
      </div>

      <Modal open={openModal2} setOpen={setOpenModal2} positionStart={false}>
        <div className="relative text-left w-11/12 md:w-2/3 lg:w-1/2 xl:w-1/3 z-50 flex flex-col text-xl font-bold bg-white rounded-2xl">
          {modalState &&
            (modalState.login ? (
              <LoginForm2
                setOpen={setOpenModal2}
                setModalState={setModalState}
              />
            ) : modalState.register ? (
              <RegisterForm
                setOpen={setOpenModal2}
                setModalState={setModalState}
                data={modalState.data}
              />
            ) : modalState.registerGoogle ? (
              <RegisterGoogleForm
                setOpen={setOpenModal2}
                data={modalState.data}
              />
            ) : (
              <ResetPassForm
                setOpen={setOpenModal2}
                setModalState={setModalState}
              />
            ))}
        </div>
      </Modal>

      <Modal {...{ open: open3, setOpen: setOpen3 }}>
        <div className="relative text-left w-11/12 md:w-2/3 lg:w-1/2 xl:w-2/5 z-50 flex flex-col text-xl font-bold bg-white rounded-2xl">
          {modalContent}
        </div>
      </Modal>

      <ResetPassComponent />
    </>
  );
};

export default Home;
