import { useContext, useRef, useState } from "react";
import GlobalContext from "../../context/GlobalContext/GlobalContext";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import firebaseConfig from "../../firebaseConfig";
import UserContext from "../../context/UserContext/UserContext";

// Firebase Config
firebase.initializeApp(firebaseConfig);
const auth = firebase.auth();
const provider = new firebase.auth.GoogleAuthProvider();
const signInWithGoogle = async () => {
  const res = await auth.signInWithPopup(provider);
  return res;
};
const signOut = async () => {
  await auth.signOut();
};

const LoginForm2 = ({ setOpen, setModalState }) => {
  const { login, navigate, loginWithGoogle, webEventInfo } =
    useContext(GlobalContext);
  const { getUserData } = useContext(UserContext);

  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const [textAlert, setTextAlert] = useState("");
  const errorAlertRef = useRef(<></>);

  const showErrorAlert = () => {
    errorAlertRef.current.classList.remove("hidden");
    errorAlertRef.current.classList.add("flex");
  };

  const hideAllAlert = () => {
    errorAlertRef.current.classList.remove("flex");
    errorAlertRef.current.classList.add("hidden");
  };

  const onSubmitForm = async (e) => {
    e.preventDefault();
    hideAllAlert();
    e.target[2].disabled = true;
    const result = await login(formData);
    e.target[2].disabled = false;
    if (result.success) {
      const resUserData = await getUserData();
      if (resUserData?.success) {
        const userData = resUserData.data;
        if (userData.role === "ADMIN") {
          navigate("/admin-area");
        } else if (userData.role === "PARTNER") {
          navigate("/partner-area");
        } else {
          // role USER
          navigate("/dashboard");
        }
      }
    } else {
      setTextAlert(
        "Login gagal. " + (result.display_message || "Silakan coba lagi nanti.")
      );
      showErrorAlert();
    }
  };

  const [passwordState, setPasswordState] = useState(true);

  // Login with Google
  const handleGoogleLogin = async () => {
    const loginDetails = await signInWithGoogle();
    if (loginDetails.user) {
      const user = loginDetails.user;
      const loginRes = await loginWithGoogle(user);
      await signOut();
      if (loginRes.success) {
        // getUserData();
        navigate("/dashboard");
      } else if (loginRes.status === 2) {
        setModalState({
          login: false,
          register: false,
          registerGoogle: true,
          resetPass: false,
          data: {
            email: user.email,
            photoURL: user.photoURL,
          },
        });
      } else {
        setTextAlert(
          loginRes.display_message || "Login gagal, silakan coba lagi nanti"
        );
        showErrorAlert();
      }
    }
  };

  return (
    <>
      {!webEventInfo ? (
        <div className="flex w-full justify-end p-7 pb-0">
          <i
            onClick={() => setOpen(false)}
            className="fas fa-times text-3xl cursor-pointer hover:text-oren transition duration-300"
          ></i>
        </div>
      ) : null}
      <div
        className={`flex flex-col gap-10 w-full px-10 pb-10 ${
          webEventInfo ? "py-10" : ""
        }`}
      >
        <h6 className="font-bold text-center">Masuk ke akunmu</h6>
        <form onSubmit={onSubmitForm} className="flex flex-col gap-3 w-full">
          <div className="w-full flex border-2 border-black rounded-xl items-center">
            <input
              className="w-full px-3 py-2 rounded-xl border-none outline-none bg-white font-content-1"
              placeholder="Masukkan emailmu"
              type="email"
              onChange={(e) => {
                setFormData({ ...formData, email: e.target.value.trim() });
              }}
            />
          </div>

          <div className="w-full flex border-2 pr-3 border-black rounded-xl items-center">
            <input
              className="w-full-5 sm:w-full-10 px-3 py-2 rounded-l-xl border-none outline-none bg-white font-content-1"
              placeholder="Masukkan kata sandimu"
              type={passwordState ? "password" : "text"}
              autoComplete="on"
              onChange={(e) => {
                setFormData({ ...formData, password: e.target.value });
              }}
            />
            <i
              onClick={() => {
                setPasswordState(!passwordState);
              }}
              className={`w-5 sm:w-10 text-abu fas ${
                passwordState ? "fa-eye-slash" : "fa-eye"
              } text-lg md:text-xl rounded-full cursor-pointer text-center`}
            ></i>
          </div>
          {/* <p
            onClick={() =>
              setModalState({
                login: false,
                register: false,
                registerGoogle: false,
                resetPass: true,
              })
            }
            className="font-content-1 self-end text-biru hover:underline cursor-pointer"
          >
            Lupa kata sandi?
          </p> */}
          <div
            className="hidden p-4 mb-4 text-sm text-red-700 bg-red-100 rounded-lg"
            role="alert"
            ref={errorAlertRef}
          >
            <svg
              className="inline flex-shrink-0 mr-3 w-5 h-5"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                clipRule="evenodd"
              ></path>
            </svg>
            <div>{textAlert}</div>
          </div>
          <button
            type="submit"
            className="rounded-xl bg-oren text-white font-content-0 font-bold px-8 py-2"
          >
            Masuk
          </button>
          {/* <p className="font-content-1 text-center">Atau</p>

          <button
            type="button"
            onClick={handleGoogleLogin}
            className="flex gap-5 justify-center items-center rounded-xl text-black bg-white border-2 border-black font-content-0 px-4 py-2"
          >
            <img
              src={`${process.env.PUBLIC_URL}/images/home-google.png`}
              className="w-4 sm:w-6"
              alt=""
            />
            Masuk dengan Google
          </button> */}
          <p className="font-content-1 text-center">
            Lupa password?{" "}
            <span
              onClick={() =>
                setModalState({
                  login: false,
                  register: false,
                  registerGoogle: false,
                  resetPass: true,
                })
              }
              className="text-biru hover:underline cursor-pointer"
            >
              Reset Password Sekarang
            </span>
          </p>
          {webEventInfo && !webEventInfo.is_allowed_register ? null : (
            <p className="font-content-1 text-center">
              Belum punya akun?{" "}
              <span
                onClick={() =>
                  setModalState({
                    login: false,
                    register: true,
                    registerGoogle: false,
                    resetPass: false,
                  })
                }
                className="text-biru hover:underline cursor-pointer"
              >
                Daftar sekarang!
              </span>
            </p>
          )}
        </form>
      </div>
    </>
  );
};

export default LoginForm2;
